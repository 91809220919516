import React from "react";
//import { Link } from "gatsby";

const ParagraphCardV2 = ({ data, context, ...props }) => {
  if (context === "frontpage") {
    return <FrontPage data={data} {...props} />;
  }

  if (context === "frontpage-bottom") {
    return <FrontPageBottom data={data} {...props} />;
  }

  if (context === "hero-small") {
    return <HeroSmall data={data} {...props} />;
  }

  const image = {
    alt: data?.image?.alt,
    src: data?.relationships?.image?.localFile?.publicURL,
  };

  return (
    <div className=" p-4 md:p-[30px]  text-[#3D434C] bg-[#F9F8F7] rounded-[20px] w-full space-y-4 md:space-y-[30px]">
      {image.src && (
        <div className="">
          <img src={`${image.src}`} alt={`${image.alt}`} />
        </div>
      )}

      <h3
        className="font-semibold text-[28px]  leading-[35px] tracking-[-0.03em] max-w-[209px]"
        dangerouslySetInnerHTML={{ __html: data.title }}
      />

      <div className="text-lg leading-[27px] ">{data.description}</div>
    </div>
  );
};

const FrontPage = ({ data, context, ...props }) => {
  const { index } = props;

  return (
    <div
      className={
        " p-[30px]  rounded-[20px]  w-full space-y-[30px] " +
        (index === 0 ? "text-white" : " text-[#3D434C]  bg-[#F9F8F7]")
      }
      style={
        index === 0
          ? {
              background:
                "linear-gradient(286.05deg, #4A72FF 6.93%, #1B4DFF 67.1%)",
            }
          : {}
      }
    >
      <h3
        className="font-semibold text-[28px]  leading-[35px] tracking-[-0.03em] max-w-[209px]"
        dangerouslySetInnerHTML={{ __html: data.title }}
      />

      <div className="text-lg leading-[27px] ">{data.description}</div>
    </div>
  );
};

const FrontPageBottom = ({ data, context, ...props }) => {
  const image = {
    alt: data?.image?.alt,
    src: data?.relationships?.image?.localFile?.publicURL,
  };

  return (
    <div className=" p-4 md:p-[30px]  text-[#3D434C] --bg-[#F9F8F7] --rounded-[20px] w-full space-y-4 md:space-y-[30px] ">
      {image.src && (
        <div className="">
          <img src={`${image.src}`} alt={`${image.alt}`} />
        </div>
      )}

      <h3
        className="font-medium text-[28px]  leading-[35px] tracking-[-0.03em] max-w-[250px]  min-h-[70px]"
        dangerouslySetInnerHTML={{ __html: data.title.replace("  ", "<br/>") }}
      />

      <div className="text-lg leading-[27px] ">{data.description}</div>
    </div>
  );
};

const HeroSmall = ({ data, context, ...props }) => {
  const image = {
    alt: data?.image?.alt,
    src: data?.relationships?.image?.localFile?.publicURL,
  };

  return (
    <div className=" text-[#3D434C]  --w-full space-y-4 md:space-y-[15px] ">
      <div className="flex flex-row justify-center items-center gap-2.5">
        {image.src && (
          <div className="">
            <img src={`${image.src}`} alt={`${image.alt}`} />
          </div>
        )}

        <h3 className="font-semibold text-[24px]  leading-[24px] tracking-[-0.03em] ">
          {data.title}
        </h3>
      </div>

      <div className="text-xl leading-[20px] text-center ">
        {data.description}
      </div>
    </div>
  );
};

export default ParagraphCardV2;
