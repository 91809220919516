import React, { Fragment } from "react";
import ParagraphCardV2 from "./cardv2";
import ParagraphCard from "./card";
import { pipesToSpan } from "../../util/common";

const ParagraphCardsContainer = ({ data, context, className,classNameCards, ...props }) => {
    
  if ( context === 'lp-video'){
    
    return (
      <div className={className}>

        {data?.title?.processed && <h2 className="text-center text-[36px] md:text-[48px] font-medium leading-[48px] md:leading-[60px] tracking-[-0.05em] max-w-[1000px] mx-auto" dangerouslySetInnerHTML={{__html: pipesToSpan(data.title.processed, 'text-[#4A72FF] font-semibold' )}} />}
        {data?.description?.processed && <div>{data.description.processed}</div>}
        <div className={classNameCards}>
          {data.relationships.cards.map((c, index) => (
            <Component data={c} key={index} context={context} />
          ))}
        </div>
      </div>
    );
  }


  if ( context === 'frontpage-bottom'){
    
    //console.log( data.relationships.cards )
    return (
      <div className={className}>

      {data?.title?.processed && 
      <h2 
      className="text-center text-[40px]  md:text-[72px] font-normal leading-[52px] md:leading-[80px] tracking-[-0.03em] max-w-[1000px] mx-auto text-[#3D434C]" 
      dangerouslySetInnerHTML={{__html: data.title.processed.replace("  ", "<br/>")}} 
      />
      }
      {data?.description?.processed && <div>{data.description.processed}</div>}
      <div className={classNameCards}>
        {data.relationships.cards.map((c, index) => (
          <Fragment key={index}>
          <Component data={c} key={index} context={context}  index={index} />
          {index < 2 && <div className="bg-[#E3E1DA] w-[1px] h-[261px] mt-8 m-4 border hidden md:block"></div>}
          </Fragment>
        ))}
      </div>
    </div>
    );
  }


  if ( context === 'hero-small'){
    
    //console.log( data.relationships.cards )
    return (
      <div className={className}>
      <div className={classNameCards}>
        {data.relationships.cards.map((c, index) => (
          <Fragment key={index}>
          <Component data={c} key={index} context={context}  index={index} />
          {index < 2 && <div className="bg-[#E3E1DA] w-[1px] h-[20px] --mt-8 m-4 border hidden md:block"></div>}
          </Fragment>
        ))}
      </div>
    </div>
    );
  }


  return (
    <div className={className}>

      {data?.title?.processed && <h2 className="text-center text-[40px]  md:text-[48px] font-medium leading-[52px] md: leading-[60px] tracking-[-0.05em] max-w-[1000px] mx-auto">{data.title.processed}</h2>}
      {data?.description?.processed && <div>{data.description.processed}</div>}
      <div className={classNameCards}>
        {data.relationships.cards.map((c, index) => (
          <Component data={c} key={index} context={context}  index={index} />
        ))}
      </div>
    </div>
  );
};


const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
    
    case "paragraph__card":
      comp = <ParagraphCard data={data} context={context} {...props} />;
      break;
    case "paragraph__cardv2":
      comp = <ParagraphCardV2 data={data} context={context} {...props} />;
      break;
   
    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};



export default ParagraphCardsContainer;
