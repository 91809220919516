import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { pipesToSpan } from "../util/common";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import HubspotForm from "react-hubspot-form";
import ArticleCard from "../components/cards/article";
import ParagraphYoutubeVideoSection from "../components/paragraphs/youtube-video-section";
import ParagraphCardsContainer from "../components/paragraphs/cards-container";

function Landing({ data: { node }, path }) {
  const {
    relationships,
    //...props
  } = node;

  //console.log(props);
  const { hero, cards_section, block, video, articles, hubspot } =
    relationships;

  const seo = getMetatags(
    node.metatag
    //node.relationships.OGImage?.localFile?.publicURL
  );

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow",
  });

  return (
    <Layout showCta={false}>
      <Seo {...seo} />

      <div className="h-[70px]"></div>

      <Hero {...hero} />

      <section className="pb-4 md:pb-[111px] md:mt-[-36px]">
        <div className="max-w-[1000px] mx-auto">
          <Component data={video} context="lp-video" />
        </div>
      </section>

      <SectionCards2a data={cards_section} />

      <SectionHubspot hubspot={hubspot} block={block} />

      <SectionArticles articles={articles} />
    </Layout>
  );
}

const SectionCards2a = ({ data, ...props }) => {
  return (
    <section>
      <div className="max-w-[1108px] mx-auto ">
        <Component
          data={data}
          context="lp-video"
          className="space-y-[70px] py-[60px]"
          classNameCards="grid grid-cols-1 md:grid-cols-3 gap-5 "
        />
      </div>
    </section>
  );
};

const SectionArticles = ({ articles }) => {
  return (
    <section className="bg-[#F9FAFB]">
      <SectionCards.Body
        className="container lg:px-0 mx-auto py-12 grid grid-cols-1 md:grid-cols-3 justify-between gap-[29px] max-w-[1108px]"
        cardClass="border border-[#D7DFE9] rounded-[10px] px-2.5 pt-2.5 pb-[30px] bg-white space-y-5 "
        items={articles.map((article) => ({
          ...article,
          image: {
            ...article.image,
            src: article.relationships?.image?.localFile?.publicURL,
          },
          category: getCategory(article),
        }))}
        Card={ArticleCard}
      />
    </section>
  );
};
const SectionHubspot = ({ hubspot, block }) => {
  return (
    <section className="pb-20" id="form">
      <div className="container relative h-[104px]">
        <div
          className="h-[369px] absolute top-0 left-0 right-0 -z-10 rounded-[20px]"
          style={{
            background:
              "linear-gradient(180deg, #F2F5FF 0%, rgba(242, 245, 255, 0) 100%)",
          }}
        ></div>
      </div>

      <SectionForm hubspot={hubspot} block={block}>
        <div className="md:ml-[31px]  mt-8 md:mt-[55px]  space-y-[34px]">
          <SectionCards.Title
            title={block.title}
            className="text-[50px] font-semibold leading-[55px] tracking-[-0.02em] text-white"
            secondClassName="text-[50px] font-semibold leading-[55px] tracking-[-0.02em] text-transparent bg-clip-text bg-gradient-to-r from-[#FFFFFF] via-[#A4B8FF] to-[#FFFFFF]"
          />

          <div className="text-lg font-[400] leading-[27px] max-w-[308px] text-white">
            {block.description}
          </div>
        </div>
      </SectionForm>
    </section>
  );
};

const getCategory = (article) => {
  let category = "-";

  const type = article?.node_type?.type;

  //console.log(article.type)
  switch (type) {
    case "blog":
      category = article.relationships?.category?.name;
      break;
    case "ebook":
      category = article.type;
      break;

    default:
      category = "--";
      break;
  }

  return category;
};

const SectionForm = ({ hubspot, children }) => {
  const ready = (form) => {
    var containers = document.getElementsByClassName("legal-consent-container");

    for (var x = 0; x < containers.length; x++) {
      var container = containers[x];

      var fieldset1 = container.closest("fieldset");
      var f = container.closest("form");

      f.append(fieldset1);
    }
  };

  return (
    <div className="container px-[55px] mx-auto py-[55px] bg-cover bg-[url('/background/form-bg.svg')]  lg:w-[1108px] mx-auto rounded-[12px]">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="md:w-1/2 ">
          {/*
            <div>{block.title}</div>
            <div>{block.description}</div> */}

          {children}
        </div>
        <div className="lg:w-1/3 xl:w-[368px] pt-6 md:pt-0 md:ml-[3%] lg:ml-0 backdrop-blur-sm">
          <div className="border border-[#F0F3F9] p-7 bg-[#FFFFFF33] rounded-[12px] lp-use-case-hubspot">
            <div className="text-center text-white text-lg leading-[27px] font-medium mb-5">
              {hubspot.title}
            </div>
            <HubspotForm
              portalId={hubspot.portal_id}
              formId={hubspot.form_id}
              //cssRequired=""
              css=" " // !important
              cssClass="sigthfull-hubspot"
              submitButtonClass=" bg-[#1C222B] py-2 px-4 rounded-full text-white text-sm fomt-medium leading-[20px]"
              errorClass="sigthfull-hubspot-error"
              errorMessageClass="sigthfull-hubspot-error-message"
              //onSubmit={(e) => console.log('Submit!')}
              //onFromSubmit={(e) => console.log('on form submit')}
              //onFormSubmitted={(e) => console.log('on onFormSubmitted')}
              onReady={ready}
              //loading={<div>Loading...</div>}
              //redirectUrl={path + "/thank-you"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Hero = ({ title, description_html, ...props }) => {
  //console.log( props )
  return (
    <section
      className="bg-no-repeat bg-top bg-cover "
      style={{
        backgroundImage: "url('/background/partners-program-video-background.png')",
      }}
    >
      <div className=" max-w-[14400px] mx-auto pt-12 lg:pt-[74px] pb-[84px] px-6 lg:px-0 bg-no-repeat bg-top">
        <div className=" space-y-[102px] ">
          <div className="space-y-8">
            <div className=" text-center">
              <div className="inline-block rounded-[4px] text-[#1B4DFF] bg-[#F2F5FF] py-[7px] px-[18px] leading-[20px] font-medium">
                {props.label}
              </div>
            </div>
            <div className="">
              <h1
                className="text-center  text-[40px] lg:text-[60px] font-medium leading-[44px] lg:leading-[66px] lg:tracking-[-0.03em] text-[#3D434C] max-w-[900px] mx-auto"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>

            <div
              className="text-center text-[20px] leading-[32px] text-[#3D434C] max-w-[900px] mx-auto"
              dangerouslySetInnerHTML={{ __html: description_html.processed }}
            />
          </div>
        </div>
      </div>
      {/* <div className="absolute top-0 bottom-0 left-0 right-0 bg-[#ffffff44] z-[-10]"></div> */}
    </section>
  );
};

const SectionCards = ({
  title,
  description = "",
  items,
  Card,
  bodyClass = "",
  cardClass = "",
}) => {
  return (
    <section className="py-12">
      <div className="container mx-auto">
        {/* <h3>{title}</h3> */}
        <SectionCards.Title title={title} />
        <div>{description}</div>
        <div className={bodyClass}>
          {items.map((item, index) => (
            <Card {...item} key={index} className={cardClass} />
          ))}
        </div>
      </div>
    </section>
  );
};

const SectionCards3 = ({
  title,
  description = "",
  items,
  Card,
  className = "",
  bodyClass = "",
  cardClass = "",
}) => {
  // arrow-1.svg

  const cards0 = items.map((item, index) => (
    <Card {...item} key={index} className={cardClass} />
  ));
  const cards = [];

  for (const key in cards0) {
    cards.push(cards0[key]);
    cards.push(<SectionCards3.Arrow key={"s" + key} />);
  }

  cards.pop();

  return (
    <section className=" py-[106px]">
      <div className="container mx-auto space-y-[60px]">
        <SectionCards.Title
          title={title}
          className="font-medium leading-[62px] text-[50px] tracking-[-1px] text-center"
        />

        <div className={bodyClass}>{cards}</div>
      </div>
    </section>
  );
};

SectionCards3.Arrow = () => {
  return (
    <div className="hidden lg:flex flex-row items-center justify-center h-[118px]">
      <img
        src="/arrow-1.svg"
        alt="arrow"
        //height="16"
        //width="16"
        className="block mx-auto mt-[16px] "
      />
    </div>
  );
};

SectionCards.Title = ({
  title,
  className,
  secondClassName = "text-blue",
  ...props
}) => {
  const t = pipesToSpan(title, secondClassName);
  return (
    <h3
      className={className}
      dangerouslySetInnerHTML={{ __html: t }}
      {...props}
    />
  );
};

SectionCards.Description = ({ description, className }) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

SectionCards.Body = ({ Card, items, className, cardClass }) => {
  const cards = items.map((item, index) => (
    <Card {...item} key={index} index={index} className={cardClass} />
  ));
  return <div className={className}>{cards}</div>;
};


const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
    case "paragraph__cards_container":
      comp = (
        <ParagraphCardsContainer data={data} context={context} {...props} />
      );
       break;
    
    case "paragraph__youtube_video_section":
      comp = (
        <ParagraphYoutubeVideoSection
          data={data}
          context={context}
          {...props}
        />
      );
      break;

    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};

export default Landing;

export const query = graphql`
  query lpVideo($id: String!) {
    node: nodeLandingPageWithVideo(id: { eq: $id }) {
      id
      title

      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }

      relationships {
        hero: field_hero {
          ...ParagraphGenericBlock
        }
        video: field_youtube_video {
          ...ParagraphYoutubeVideoSection
        }
        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
        block: field_content_block {
          ...ParagraphGenericBlock
        }
        cards_section: field_cards_section {
          ...ParagraphCardsContainer
        }

        articles: field_articles {
          ... on node__blog {
            __typename
            title
            description: field_description2
            path {
              alias
            }
            image: field_image {
              alt
              title
            }
            relationships {
              image: field_image {
                localFile {
                  publicURL
                }
              }
              category: field_category {
                name
              }
            }
            node_type {
              type: drupal_internal__target_id
            }
          }
        }
      }
    }
  }
`;
