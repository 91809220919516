import React, { useState } from "react";
import YouTube from "react-youtube";
import { BrowserView, MobileView } from "react-device-detect";

import PlayIcon from '../../images/play-circel.svg'

const ParagraphYoutubeVideoSection = ({ data, context, ...props }) => {
  
  //console.log( data )

  const [show, setShow] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(null);

  const { /*title,*/ youtube } = data;

  const onReady = (player) => {
    setVideoPlayer(player);
  };

  const handleClick = () => {
    setShow(true);
    videoPlayer.playVideo();
  };

  const image = {
    alt: data?.image?.alt,
    src: data?.relationships?.image?.localFile?.publicURL,
  };


  return (
    <div className="  rounded-[20px]  w-full h-auto ">
      {!show && (
        <button className="m-0 p-0 block outline-0 relative group" onClick={handleClick}>
          <img src={`${image.src}`} alt={`${image.alt}`} className="rounded-[20px]"
          style={{ boxShadow: "0px 20px 20px 0px #0000001A" }}
          />
          <div className="absolute top-2 md:top-[115px] left-[50.5%] md:left-[46.5%] text-white space-y-3 text-center scale-75">
            <div className="mx-auto inline-block"><img src={PlayIcon} alt="Play" className="inline-block" /></div>
            <div className="leading-[16px] tracking-[-0.03em] font-medium group-hover:font-semibold">Play</div>
          </div>
        </button>
      )}

      <div className={show ? "block  " : "hidden"}>
        <BrowserView>
          <Video
            video_id={youtube.video_id}
            onReady={onReady}
            width="1000px"
            height="560px"
          />
        </BrowserView>
        <MobileView>
          <Video video_id={youtube.video_id} onReady={onReady} width="100%" />
        </MobileView>
      </div>

    </div>
  );
};

const Video = ({
  video_id,
  onReady,
  className = "",
  width = "",
  height = "",
}) => {
  const opts = {
    // height: "100%",
    width: width, // "100%",
    // height: "472",
    // width: "840",
    /*height: "450",*/
    /*width: "800",*/
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      loop: 1,
      rel: 0,
    },
  };

  if (height !== "") {
    opts.height = height;
  }

  return (
    <YouTube
      videoId={video_id}
      opts={opts}
      className={className + " "}
      /*style={{width: '100% !important'}}*/
      onReady={(event) => onReady(event.target)}
    />
  );
};
export default ParagraphYoutubeVideoSection;
